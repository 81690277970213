import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import imagesloaded from "imagesloaded"
import Layout from "../components/Layout"
import MetaData from "../components/meta"

// Import Styles
import "../styles/about.scss"

// Import Images
import Linkedin from "../images/inline-svgs/linkedin.svg"
import Twitter from "../images/inline-svgs/twitter.svg"

interface Props {
  data: any
  location: any
}

const AboutPage = ({ data, location }: Props) => {
  useEffect(() => {
    imagesloaded(
      ".about__header",
      {
        background: true,
      },
      function () {
        document.querySelector(".about__header")?.classList.remove("bg-hidden")
      }
    )
  }, [])

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.current_page,
        }}
      />

      <div className="about-page">
        {/* Header */}
        <section className="about__header bg-hidden">
          <div className="wrapper-1200">
            <h1 className="title">We are Traefik Labs</h1>
            <p className="sub-title">We make networking boring.</p>
          </div>
        </section>

        {/* Main */}
        <section className="about__main">
          <div className="about__main__about-us">
            <div className="wrapper-1200">
              <hr className="hr-tiny" />
              <h2>About Traefik Labs</h2>
              <div className="bio">
                <p>
                  The combination of cloud computing adoption trends and the
                  recent growth of containerization technologies has led to a
                  shift in mindsets regarding how enterprise applications should
                  be architected.
                </p>

                <p>
                  Founded in 2016, Traefik Labs (formerly Containous) is the
                  leading cloud-native networking company that offers a powerful
                  stack to ease the deployment of modern IT environments.
                </p>

                <p>
                  Traefik Labs’ mission is to bring the first cloud-native
                  networking stack for dynamic infrastructure to simplify cloud
                  and microservices adoption journey for all enterprises.
                </p>

                <p>
                  Traefik Labs’ development model is based on open source
                  solutions. We believe that community-backed open-source
                  software along with enterprise-grade consulting provides our
                  customers with the confidence they need to focus on their
                  business.
                </p>
              </div>

              <div className="member-orgs">
                <h4 className="sub-header-small">Proud Member Of</h4>
                <div className="member-orgs__logos">
                  <div className="member-orgs__logo">
                    <img
                      srcSet="/images/about/member-orgs/cloud-native/cloud-native-logo.png 1x,
                                        /images/about/member-orgs/cloud-native/cloud-native-logo@2x.png 2x,
                                        /images/about/member-orgs/cloud-native/cloud-native-logo@3x.png 3x"
                      src="/images/about/member-orgs/cloud-native/cloud-native-logo.png"
                      alt="Cloud Native Computing Foundation"
                    />
                  </div>

                  <div className="member-orgs__logo">
                    <img
                      srcSet="/images/about/member-orgs/linux/linux-logo.png 1x,
                                        /images/about/member-orgs/linux/linux-logo@2x.png 2x,
                                        /images/about/member-orgs/linux/linux-logo@3x.png 3x"
                      src="/images/about/member-orgs/linux/linux-logo.png"
                      alt="The Linux Foundation"
                    />
                  </div>
                </div>
              </div>

              <div className="images">
                <StaticImage
                  src="../images/about/about/image.jpg"
                  alt="team photographs"
                  placeholder="blurred"
                  width={1250}
                />
              </div>
            </div>
          </div>

          <div className="about__main__leadership">
            <div className="wrapper-1200">
              <hr className="hr-tiny" />
              <h2>Our Leadership</h2>
              <div className="members">
                <div className="member">
                  <div className="member__wrapper">
                    <div className="member__image">
                      <GatsbyImage
                        image={data.emile_vauge.childImageSharp.gatsbyImageData}
                        alt="Emile Vauge"
                      />
                    </div>
                    <div className="member__name">Emile Vauge</div>
                    <div className="member__title">Founder &amp; CEO</div>
                    <div className="member__links">
                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://www.linkedin.com/in/emilevauge/"
                      >
                        <Linkedin />
                      </a>
                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://twitter.com/emilevauge"
                      >
                        <Twitter />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="member">
                  <div className="member__wrapper">
                    <div className="member__image">
                      <GatsbyImage
                        image={
                          data.gerald_croes.childImageSharp.gatsbyImageData
                        }
                        alt="Gerald Croes"
                      />
                    </div>
                    <div className="member__name">Gerald Croes</div>
                    <div className="member__title">VP of Engineering</div>
                    <div className="member__links">
                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://www.linkedin.com/in/gerald-croes/"
                      >
                        <Linkedin />
                      </a>
                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://twitter.com/geraldcroes"
                      >
                        <Twitter />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="member">
                  <div className="member__wrapper">
                    <div className="member__image">
                      <GatsbyImage
                        image={
                          data.aymeric_bordet.childImageSharp.gatsbyImageData
                        }
                        alt="Aymeric Bordet"
                      />
                    </div>
                    <div className="member__name">Aymeric Bordet</div>
                    <div className="member__title">Head of Finance</div>
                    <div className="member__links">
                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://www.linkedin.com/in/aymeric-bordet-2042323/"
                      >
                        <Linkedin />
                      </a>
                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://twitter.com/aymeric_bordet"
                      >
                        <Twitter />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="member">
                  <div className="member__wrapper">
                    <div className="member__image">
                      <GatsbyImage
                        image={
                          data.adrien_martinet.childImageSharp.gatsbyImageData
                        }
                        alt="Adrien Martinet"
                      />
                    </div>
                    <div className="member__name">Adrien Martinet</div>
                    <div className="member__title">Head of Sales</div>
                    <div className="member__links">
                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://www.linkedin.com/in/adrimartinet/"
                      >
                        <Linkedin />
                      </a>
                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://twitter.com/martinet_adrien"
                      >
                        <Twitter />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="member">
                  <div className="member__wrapper">
                    <div className="member__image">
                      <GatsbyImage
                        image={
                          data.marie_ponseel.childImageSharp.gatsbyImageData
                        }
                        alt="Marie Ponseel"
                      />
                    </div>
                    <div className="member__name">Marie Ponseel</div>
                    <div className="member__title">VP of Marketing</div>
                    <div className="member__links">
                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://www.linkedin.com/in/marieponseel/"
                      >
                        <Linkedin />
                      </a>
                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://twitter.com/marieponseel"
                      >
                        <Twitter />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="about__main__investors">
            <div className="wrapper-1200">
              <hr className="hr-tiny" />
              <h2>Our Investors</h2>
              <div className="investors">
                <a
                  href="https://www.balderton.com"
                  target="_blank"
                  rel="noopener"
                  className="investor"
                >
                  <GatsbyImage
                    image={
                      data.investors__balderton.childImageSharp.gatsbyImageData
                    }
                    alt="Balderton"
                  />
                </a>
                <a
                  href="https://www.elaia.com/"
                  target="_blank"
                  rel="noopener"
                  className="investor"
                >
                  <GatsbyImage
                    image={
                      data.investors__elaia.childImageSharp.gatsbyImageData
                    }
                    alt="Elaia"
                  />
                </a>
                <a
                  href="http://www.360cap.vc/"
                  target="_blank"
                  rel="noopener"
                  className="investor"
                >
                  <GatsbyImage
                    image={data.investors__360.childImageSharp.gatsbyImageData}
                    alt="360 Capital Partners"
                  />
                </a>
                <a
                  href="https://www.kimaventures.com/"
                  target="_blank"
                  rel="noopener"
                  className="investor"
                >
                  <GatsbyImage
                    image={data.investors__kima.childImageSharp.gatsbyImageData}
                    alt="Kima Ventures"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* CTA */}
        <section className="about__footer">
          <div className="wrapper-1200">
            <h2>Interested in joining Traefik Labs?</h2>
            <div className="container">
              <Link
                className="button--primary button--extra-padding"
                to="/careers/#open-positions"
              >
                View Our Open Positions
              </Link>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  {
    emile_vauge: file(relativePath: { eq: "about/members/emile/emile.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 280, height: 336, layout: FIXED)
      }
    }
    vincent_pineau: file(
      relativePath: { eq: "about/members/vincent/vincent.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 280, height: 336, layout: FIXED)
      }
    }
    gerald_croes: file(
      relativePath: { eq: "about/members/gerald/gerald.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 280, height: 336, layout: FIXED)
      }
    }
    aymeric_bordet: file(
      relativePath: { eq: "about/members/aymeric/aymeric.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 280, height: 336, layout: FIXED)
      }
    }
    adrien_martinet: file(
      relativePath: { eq: "about/members/adrien/adrien.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 280, height: 336, layout: FIXED)
      }
    }
    marie_ponseel: file(relativePath: { eq: "about/members/marie/marie.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 280, height: 336, layout: FIXED)
      }
    }
    investors__balderton: file(
      relativePath: { eq: "about/investors/balderton/balderton.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 195, height: 35, layout: FIXED)
      }
    }
    investors__elaia: file(
      relativePath: { eq: "about/investors/elaia/elaia.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 195, height: 59, layout: FIXED)
      }
    }
    investors__360: file(relativePath: { eq: "about/investors/360/360.png" }) {
      childImageSharp {
        gatsbyImageData(width: 196, height: 113, layout: FIXED)
      }
    }
    investors__kima: file(
      relativePath: { eq: "about/investors/kima/kima.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 195, height: 109, layout: FIXED)
      }
    }
    current_page: ghostPage(slug: { eq: "about-us" }) {
      ...PageMetaFields
    }
  }
`
